import { render, staticRenderFns } from "./products.vue?vue&type=template&id=c99799c2&"
import script from "./products.vue?vue&type=script&lang=js&"
export * from "./products.vue?vue&type=script&lang=js&"
import style0 from "./products.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QTable,QTh,QBtn,QMenu,QTd,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QPagination,QSelect,QInput,QDrawer});qInstall(component, 'directives', {ClosePopup});
