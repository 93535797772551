import { render, staticRenderFns } from "./productDetails.vue?vue&type=template&id=302863e0&scoped=true&"
import script from "./productDetails.vue?vue&type=script&lang=js&"
export * from "./productDetails.vue?vue&type=script&lang=js&"
import style0 from "./productDetails.vue?vue&type=style&index=0&lang=stylus&"
import style1 from "./productDetails.vue?vue&type=style&index=1&id=302863e0&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "302863e0",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QLayout,QPage,QCard,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QBtn,QIcon,QEditor,QInput,QSelect,QDrawer,QList,QItem,QDialog,QCardSection,QAvatar,QCardActions,QMenu,QTable,QField});qInstall(component, 'directives', {ClosePopup});
