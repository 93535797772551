<template>
  <div v-if="$store.state.pricing.defs">
    <q-page-container>
      <q-page>
        <q-table
          id="products"
          class="fulltable"
          :data="products"
          :columns="columns"
          :selection="shortcut ? 'none' : 'multiple'"
          :loadind="loading"
          row-key="id"
          :pagination.sync="pagination"
          :selected.sync="selected"
          @request="fetch"
          @row-click="rowclick"
          @row-dblclick="dblclick"
          :rows-per-page-options="[10,20,50,100]"
        >
          <template v-slot:header-cell="props">
            <q-th class="q-th-header" :props="props">
                  <div class="cell-header">
                    <q-btn dense size="sm" flat round :color="inUse(props.col.name) ? 'negative' : 'white'" class="sp" v-if="props.col.filter" icon="filter_list">
                      <q-menu>
                        <filter-base :settings="$store.state.pricing.defs.defs2[props.col.name].settings" :name="props.col.name" @update="resetFetch" @remove="removeFilter" :serie="$store.state.pricing.defs.defs2[props.col.name].timeserie">
                            <component
                            :is="componentbyattribute(props.col.name, 'filter')"
                            slot-scope="base"
                            :name="props.col.name"
                            :proxy="base.proxy"
                            :units="$store.state.general.settings.units[$store.state.pricing.defs.defs2[props.col.name].type === 'number'?'number':'amount']" />
                        </filter-base>
                      </q-menu>
                    </q-btn>
                    {{ props.col.label }}
                    <q-btn dense size="sm" flat round
                      v-if="props.col.sort"
                      :icon="getIcon(props.col.sort)"
                      :color="props.col.sort === '.' ? 'white' : 'negative'"
                      @click="setSort(props.col)"
                    />
                  </div>
            </q-th>
          </template>
          <template v-slot:body-cell="props">
            <q-td :props="props">

                <attribute v-if="props.value !== undefined" :data="props.value" :def="$store.state.pricing.defs.defs2[props.col.name]" :locale="locale"
                  mode="view-only" space="standard" wrap="yes"/>
            </q-td>
          </template>
          <template v-slot:bottom="props">
            <div class="tablebottom">
              <div class="lgselected">
                <span v-if="!shortcut">┗ {{$t('{{count\}\} part of {{total\}\} selected', { count: selected.length, total: pagination.rowsNumber })}} </span>
                <q-btn-dropdown v-if="shortcut" dense color="secondary" :label="$t('Action...')">
                  <q-list>
                    <q-item v-close-popup  class="bg-negative text-white">
                      <q-item-section>
                        <q-item-label>
                          {{$t('Beware : Any action will be applied to {{count\}\} parts!', { count: pagination.rowsNumber })}}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="!cannotEdit() && toggleDrawer('move')">
                      <q-item-section>
                        <q-item-label>{{$t('Move')}}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="!cannotEdit() && toggleDrawer('approve')">
                      <q-item-section>
                        <q-item-label>{{$t('Approve')}}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="!cannotEdit() && toggleDrawer('resend')">
                      <q-item-section>
                        <q-item-label>{{$t('Resend to ERP')}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
                <q-btn dense color="secondary" v-else :disable="cannotSegment() || selected.length === 0" @click="toggleDrawer('move')">{{$t('Move')}}</q-btn>
              </div>
              <div class="lgpagination">
                <q-pagination v-model="pagination.page" :max="props.pagesNumber"
                  :max-pages="6"
                  :boundary-numbers="true"/>
                <div v-html="$t('{{start}} - {{end}} of {{count}} item', { count: pagination.rowsNumber, start: (pagination.page - 1) * pagination.rowsPerPage + 1, end: pagination.page * pagination.rowsPerPage})">
                </div>
                <div class="lgrowperpage">
                  <q-select :label="$t('Items per page')" dense filled square v-model="pagination.rowsPerPage" :options="[10,20,50,100,500,1000]"/>
                </div>
              </div>
              <div class="lg-time-travel">
                <q-input debounce="1000" v-model="when" :label="$t('Time cursor')"  filled square dense mask="##/##/####" />
              </div>
            </div>
          </template>
        </q-table>

      </q-page>
      <div class="settings">
        <q-btn dense color="secondary" title="Move parts" icons="check_icon" v-if="drawer.type === 'move'"/>
        <q-btn dense color="primary" title="Data grid settings" icon="settings" @click="toggleDrawer('settings')" />
        <q-btn :disable="cannotAddProducts()" v-if="$store.state.general.settings.fetchparts" dense color="primary" title="Fetch Parts" icon="get_app" @click="toggleDrawer('fetch')" />
      </div>
    </q-page-container>
    <q-drawer v-model="draweron" :content-class="['shadow-2']" side="right">
      <settingsDrawer
        v-if="drawer.type === 'settings'"
        :data="drawer"
        @drop-column="dropColumn"
        @add-column="addColumn"
        @reorder-columns="reorderColumns"
        @export-data="exportData"
        @reload="resetFetch"
      ></settingsDrawer>
      <detailsProduct v-if="drawer.type === 'details'" :data="drawer"></detailsProduct>
      <fetchDrawer v-if="drawer.type=== 'fetch'" :data="drawer"/>
      <moveDrawer v-if="drawer.type=== 'move'" :data="drawer" :selected="selected" @update="refetch"/>
      <resend-drawer v-if="drawer.type=== 'resend'" :data="drawer"  @update="refetch"/>
      <approve-drawer v-if="drawer.type=== 'approve'" :data="drawer" @update="refetch" @load-filter="loadfilter"/>
    </q-drawer>
  </div>
</template>

<script>
import pricingUtilities from '@cabinetmariel/pricing-utilities';
import { can } from '@cabinetmariel/scopes';
import detailsProduct from './drawer/detailsProductDrawer';
import settingsDrawer from './drawer/settingsDrawer';
import fetchDrawer from './drawer/fetchDrawer';
import moveDrawer from './drawer/moveDrawer';
import resendDrawer from './drawer/resendDrawer.vue';
import approveDrawer from './drawer/approveDrawer.vue';
import filterBase from './HeadersFilters/filterBase.vue';
import attribute from '../../components/attributesDisplay/attribute';
import { localization } from '../../lib/mixins';

const { utils } = pricingUtilities;

const supportedtypes = [
  'string',
  'localizedstring',
  'segmentation',
  'pricing',
  'number',
  'boolean',
  'amount',
  'interval',
  'unsupported',
  'tag',
  'documentset',
];

const filterList = ['string', 'collection', 'localizedstring', 'pricing', 'boolean', 'segmentation', 'number', 'amount'];

const supportedcomponents = supportedtypes.reduce((_, t) => {
  // eslint-disable-next-line
  _[t] = () => import('../../components/attributesDisplay/' + t); // eslint-disable-line
  return _;
}, {});

const supportedtypesFilters = [
  'hString',
  'hLocalizedstring',
  'hSegmentation',
  'hPricing',
  'hBoolean',
  'hNumber',
  'hCollection',
  'hAmount',
];
const filterAliases = {
  hCollection: 'hString',
  hLocalizedstring: 'hString',
  hAmount: 'hPricing',
  hNumber: 'hPricing',
};

const supportedFiltres = supportedtypesFilters.reduce((_, t) => {
  // eslint-disable-next-line
  _[t] = () => import('./HeadersFilters/' + (filterAliases[t] || t));
  return _;
}, {});
function togglesort(sort) {
  const sorts = {
    '.': '+',
    '+': '-',
    '-': '+',
  };
  return sorts[sort || '.'];
}

export default {
  mixins: [localization],
  components: {
    ...supportedcomponents,
    ...supportedFiltres,
    detailsProduct,
    settingsDrawer,
    attribute,
    fetchDrawer,
    moveDrawer,
    resendDrawer,
    approveDrawer,
    filterBase,
  },
  data() {
    return {
      test: false,
      drawer: {
        type: undefined,
        data: undefined,
      },
      products: [],
      selectedColumns: (localStorage[this.filterstore])
        ? JSON.parse(localStorage[this.filterstore])
        : [],
      loading: true,
      selected: [],
      columns: [
        // {
        //   label: 'Reference',
        //   field: 'name',
        //   align: 'left',
        //   name: 'name',
        //   filter: true,
        //   model: null,
        //   sort: '.',
        // },
      ],
      pagination: {
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 10,
      },
      locale: this.$store.state.auth.locale,
    };
  },
  watch: {
    defs(newDefs) {
      this.setup();
    },
    selected(n, o) {
      if (this.drawer.type !== 'move') return;
      if (o.length && !n.length) {
        this.toggleDrawer('move');
      }
    },
    shortcut(id) {
      this.fetchShortcut(id || '00000000-0000-0000-0000-000000000000');
    },
    'pagination.page': function pagechange(n, o) {
      if (n !== o) this.fetch({ pagination: this.pagination });
    },
  },
  computed: {
    shortcut() {
      return this.$route.query.shortcut;
    },
    filterstore() {
      return `${this.shortcut ? 'filter' : 'catalog'}_columns`;
    },
    when: {
      get() {
        const d = this.$store.state.catalog.when || (new Date());
        return this.toLocaleDate(d);
      },
      set(v) {
        this.$store.commit('catalog/setWhen', this.fromLocaleDate(v));
        this.resetFetch();
      },
    },
    catalog() {
      return this.$store.state.catalog.data;
    },
    draweron() {
      return this.drawer.type !== undefined;
    },
    defs() {
      const { defs } = this.$store.state.pricing.defs;
      return Object.entries(defs).map(([name, attr]) => ({
        label: this.localized(attr.label),
        type: attr.type,
        name: attr.name,
        id: name,
      }));
    },
  },
  mounted() {
    this.$store.commit('header/setHeader', {
      title: 'Catalog',
    });
    if (this.defs.length) {
      this.setup();
    }
  },
  methods: {
    setup() {
      Promise.all([
        this.fetchShortcut(this.shortcut || '00000000-0000-0000-0000-000000000000'),
        this.$axios.get('/profiles/settings'),
      ]).then(([, response]) => {
        // get columns
        const { columns } = response.data.data;
        if (columns) {
          this.setSelectedColumns(columns);
          this.setColumns();
        }
        this.initSelectedColumns();
      });
    },
    fetchShortcut(id) {
      return (id === '00000000-0000-0000-0000-000000000000'
        ? Promise.resolve({ data: {} })
        : this.$axios.get(`/filters/${id}`)).then((response) => {
        const filter = response.data.data;
        this.loadfilter(filter);
        return Promise.resolve();
      });
    },
    loadfilter(f) {
      if (f === undefined) {
        // ok we're in a new filter
        this.setSelectedColumns(JSON.parse(localStorage.catalog_columns || '[]'));
        this.setColumns();
        this.removeFilter();
        return;
      }
      if (!f.filter) return;
      this.setSelectedColumns(f.filter.columns);
      this.$store.commit('catalog/setFilters', f.filter.filters || []);
      this.setColumns();
      this.refetch();
    },
    inUse(name) {
      if (this.$store.state.catalog.filters.findIndex((f) => f.name === name) >= 0) return true;
      return false;
    },
    cannotAddProducts() {
      return !can('products.create', this.$store.state.auth.user.scopes);
    },
    cannotEdit() {
      return !can('products', this.$store.state.auth.user.scopes);
    },
    cannotSegment() {
      return !can('segmentation', this.$store.state.auth.user.scopes);
    },
    refetch() {
      this.selected = [];
      this.fetch({ pagination: this.pagination });
    },
    dropColumn(val) {
      this.setSelectedColumns(this.selectedColumns.filter((c) => c !== val));
      this.setColumns();
      // this.fetch({ pagination: this.pagination }, true);
    },
    reorderColumns(reordered) {
      this.setSelectedColumns(reordered);
      this.setColumns();
      this.fetch({ pagination: this.pagination }, true);
    },
    addColumn(val) {
      this.setSelectedColumns([...this.selectedColumns, val]);
      this.setColumns();
      this.fetch({ pagination: this.pagination }, true);
    },
    toggleDrawer(item, row) {
      if (item !== 'details' && this.drawer.type && item === this.drawer.type) {
        this.drawer.type = undefined;
        return;
      }
      this.drawer.type = item;
      switch (item) {
        case 'settings':
          this.drawer.data = {
            columns: this.selectedColumns,
          };
          break;
        case 'details':
          // Ugly fix because drawer watch on props isn't trigered if only the drawer.data change.
          this.drawer = {
            data: row,
            type: this.drawer.type,
          };
          break;
        case 'move':
          break;
        default:
      }
    },
    setSort({ name }) {
      // REMOVE 'FOR' WHEN ABLE TO SORT MULTIPLE COLS
      this.columns.forEach((column) => {
        column.sort = column.name === name ? togglesort(column.sort) : '.';
      });
      this.resetFetch();
    },
    getIcon(sort) {
      if (sort === '+') {
        return 'keyboard_arrow_up';
      } if (sort === '-') {
        return 'keyboard_arrow_down';
      }
      return 'keyboard_arrow_right';
    },
    rowclick(evt, row) {
      this.toggleDrawer('details', row);
    },
    dblclick(evt, row) {
      window.open(`/products/${row.id}`, '_blank');
    },
    componentbyattribute(name, type) {
      // const attribute = Object.values(this.$store.state.pricing.defs.defs).find(item => item.name === name);
      const def = this.$store.state.pricing.defs.defs2[name];
      if (def) {
        if (type === 'component') {
          return supportedtypes.indexOf(def.type) < 0
            ? 'unsupported'
            : def.type;
        } if (type === 'filter') {
          const attibuteType = `h${utils.capitalize(def.type)}`;
          // console.error('xxxxx', name, type, attibuteType);
          return supportedtypesFilters.indexOf(attibuteType) < 0
            ? 'unsupported'
            : attibuteType;
        }
      }
      return 'unsupported';
    },
    parseFilters() {
      return JSON.stringify(this.$store.state.catalog.filters.reduce((_, f) => {
        let { value } = f;
        if (!Array.isArray(value)) value = [value];
        Array.prototype.push.apply(_, value.map((v) => ({
          column: f.name,
          op: v.action,
          args: Array.isArray(v.value) ? v.value : [v.value],
        })));
        Array.prototype.push.apply(_, value.filter((v) => !!v.noempty).map((v) => ({
          column: f.name,
          op: 'not_empty',
          args: [],
        })));
        return _;
      }, []));
    },
    hasFilters() {
      return this.$store.state.catalog.filters.length > 0;
    },
    parseSorts() {
      let result = '';
      for (let i = 0; this.columns.length > i; i += 1) {
        const col = this.columns[i];
        if (col.sort === '+' || col.sort === '-') {
          result += `${col.sort}${col.name},`;
        }
      }
      return result.substr(0, result.length - 1);
    },
    hasSorts() {
      for (let i = 0; this.columns.length > i; i += 1) {
        const col = this.columns[i];
        if (col.sort !== '.') return true;
      }
      return false;
    },
    removeFilter(name) {
      this.$store.commit('catalog/resetFilters', name);
      this.resetFetch();
    },
    resetFetch() {
      this.pagination.page = 1;
      this.fetch({ pagination: this.pagination });
    },
    hasSelectedColumns() {
      return this.selectedColumns && this.selectedColumns.length > 0;
    },
    setSelectedColumns(array) {
      if (!array) array = [];
      this.selectedColumns = array.filter((c) => (c !== 'id'));
      localStorage[this.filterstore] = JSON.stringify(array);
    },
    setColumns() {
      this.columns = this.selectedColumns.map((name) => {
        const def = this.defs.find((d) => d.name === name);
        if (!def) console.error('fail to find def', name);
        return {
          label: def ? def.label : name,
          field: name,
          align: 'left',
          name,
          model: null,
          ...(def && filterList.includes(def.type) && { filter: true }),
          ...(def && filterList.includes(def.type) && { sort: '.' }),
        };
      });
      this.drawer.data = {
        columns: this.selectedColumns,
      };
    },
    arrayIsEqual(a, b) {
      return (
        a.length === b.length && a.every((value, index) => value === b[index])
      );
    },
    getParams(pagination, locale, config = {}) {
      return {
        params: {
          ...(locale && { locale }),
          ...config,
          ...(this.hasFilters() && { '@filters': this.parseFilters() }),
          ...(this.hasSorts() && { sort: this.parseSorts() }),
          ...(pagination && { '@limit': pagination.rowsPerPage }),
          ...(pagination && {
            '@offset': (pagination.page - 1) * pagination.rowsPerPage,
          }),
          ...(this.hasSelectedColumns() && {
            columns: this.selectedColumns.join(','),
          }),
          when: (this.$store.state.catalog.when || (new Date())).toISOString().split('T')[0],
        },
      };
    },
    exportData(config) {
      this.loading = true;
      const locale = (this.$store.state.auth.user && this.$store.state.auth.user.locale)
        ? this.$store.state.auth.user.locale
        : 'en-US';
      const params = this.getParams(undefined, locale, config);
      params.responseType = 'blob';
      this.$axios.get('/products/download', params).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx');
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },
    fetch({ pagination }, setColumns) {
      this.loading = true;
      const params = this.getParams(pagination);
      this.$axios
        .get('/products', params)
        .then((response) => ({
          products: response.data.data,
          meta: response.data.meta,
        }))
        .then(({ products, meta }) => {
          products.forEach((p) => {
            p.id = p.id.v;
          });
          this.products = products;
          this.pagination = pagination;
          this.pagination.rowsNumber = meta.count;
          if (setColumns) {
            // we need to make sure things stay ordered :
            const updatedcolumns = meta.columns.sort((a, b) => this.selectedColumns.indexOf(a) - this.selectedColumns.indexOf(b));
            this.setSelectedColumns(updatedcolumns);
            this.setColumns();
          }
          this.loading = false;
        });
    },
    sort(data) {
      return data;
    },
    initSelectedColumns() {
      this.fetch({ pagination: this.pagination }, true);
    },
  },
};
</script>

<style lang="stylus">

.settings {
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  position: fixed;
  z-index: 950;
  top: 50px;
  right: 0;
  .q-btn {
    display: block;
    border-radius 0
  }
  .q-btn:last-child {
    border-bottom-left-radius 1rem
  }
}

.settings:hover {
  opacity: 1;
}

.q-th-header:last-child {
  padding-right: 60px;
}

.cell-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-top {
  max-height: 20px !important;
  padding: 8px 24px;
}

.popover {
}

.pointer {
  cursor: pointer;
}

.grey {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.sp {
  padding: 10px;
  cursor: pointer;
}

.fulltable {
  table.q-table {
    //width: unset;
    //max-width unset;
  }
  .tablebottom {
    flex 1
    display flex;
    column-gap 1rem
    .lgselected {
      padding-left: 1rem;
    }
    .lgpagination {
      flex 1
      justify-content flex-end
      align-items: center;
      display flex
      column-gap: 1rem;
      .q-pagination {
        flex: 1;
        justify-content: center;
      }
      .lgrowperpage {
        .q-select {
          min-width: 150px
        }
      }
    }
  }
}
</style>
